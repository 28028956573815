<template>
  <div class="field2ColumnFlex">
    <span class="field2Caption">{{ examplePage1Data?.moneyFieldLabel }}</span>
    <span :class="field2ExplanationClass">({{ examplePage1Data?.moneyFieldLabelHelpCaption }})</span>
  </div>
</template>

<script>
export default {
  name: "moneyExamplePage1",
  props: ['examplePage1Data'],

  data() {
    return {
      field2ExplanationClass: null
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.field2ExplanationClass = "field2ExplanationDark";
    } else {
      this.field2ExplanationClass = "field2ExplanationLight";
    }
  }
}
</script>

<style scoped>
.field2ColumnFlex {
  display: flex;
  flex-direction: column;
}

.field2Caption {
  align-self: center;
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: darkgoldenrod;
  filter: brightness(1.15);
}

.field2ExplanationDark {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: #BABABA;
}

.field2ExplanationLight {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: dimgray;
  filter: brightness(0.75);
}
</style>