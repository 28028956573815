<template>
    <section class="mainColumnSuccessesFeed">
        <SuccessItem v-for="success in successes" :success="success" :key="success.successId" @removeSuccess="removeSuccess"/>

        <div ref="observer" class="observerSuccessesFeed">

        </div>
    </section>

</template>

<script>
import SuccessItem from './SuccessItem.vue';
import { ref } from "vue";

export default {
    name: 'SuccessFeed',
    components: {
        SuccessItem,
    },
    props: {
        successes: {
            type: Array,
            required: true,
        }
    },

    mounted() {
        this.handleFeedScroll();

        //***
        var options = {
            rootMargin: '0px',
            threshold: 1.0,
        };

        var callback = (entries, observer) => {
            if (entries[0].isIntersecting /*&& this.page < this.totalPages*/) {
                this.$emit('loadMoreSuccessesEmit');
            }
        };

        var observer = new IntersectionObserver(callback, options);
        observer.observe(this.$refs.observer);
    },

    methods: {
        removeSuccess(successId) {
            this.$emit('removeSuccess', successId);
        },

        handleFeedScroll() {
            window.Telegram.WebApp.onEvent('viewportChanged', () => {
                //window.Telegram.WebApp.expand();
                //this.$emit('appViewportChanged');
                //window.scroll(0, window.document.body.scrollHeight);

                //var root = document.documentElement;
                
                //root.style.setProperty('--tg-viewport-height', '100vh');
                //root.style.setProperty('--tg-viewport-stable-height', '100vh');

                //window.scrollTo(0, 1);
                /*
                let data = {
                    height: true,
                    is_stable_height: true,
                };

                window.Telegram.WebApp.setViewportHeight(data);
                */
            });

            /*
            const scrollableElement = document.querySelector(".mainColumnSuccessesFeed");

            scrollableElement.addEventListener("touchstart", () => {
                //if (window.scrollY === 0) {
                    //window.Telegram.WebApp.expand();
                    //window.scrollTo(0, 1);
                //}
            });
            */
        }
    }
}
</script>

<style>
.mainColumnSuccessesFeed {
    /*max-height: 0 !important;*/

    height: calc(var(--tg-viewport-stable-height) - 17vh);
    overflow-y: scroll;
    /*transition: max-height var(--tg-viewport-stable-height);*/
    
    /*
    position: static !important;
    overscroll-behavior: none;
*/
    /*
    height: 100%;
    min-height: 100%;
    align-items: stretch;
    */

    /*
    display: flex;
    flex-direction: column;
    */
}

.topObserverSuccessesFeed {
    margin-top: 1vh;
}

.observerSuccessesFeed {
    margin-top: -1vh;
    /*
    height: 30px;
    background: green;
    */
}
</style>