<template>
  <div class="squareFlex">
    <div class="square">

      <div class="headerRow">
        <i-element-status-of-the-day class="iElement" v-bind:status-of-the-day-data="statusOfTheDayData" />
      </div>

      <div class="flexGrowElement"/>

      <div class="bottomRow">
        <div class="bottomFlexRow">
          <money-element-status-of-the-day class="moneyElement"
                                           v-bind:status-of-the-day-data="statusOfTheDayData" />

          <peoples-element-status-of-the-day class="peoplesElement"
                                             v-bind:status-of-the-day-data="statusOfTheDayData" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import IElementStatusOfTheDay from "@/components/ui/StatusOfTheDayUI/IElementStatusOfTheDay";
import MoneyElementStatusOfTheDay from "@/components/ui/StatusOfTheDayUI/MoneyElementStatusOfTheDay";
import PeoplesElementStatusOfTheDay from "@/components/ui/StatusOfTheDayUI/PeoplesElementStatusOfTheDay";

export default {
  name: "SquareStatusOfTheDay",
  components: {PeoplesElementStatusOfTheDay, MoneyElementStatusOfTheDay, IElementStatusOfTheDay},
  props: ['statusOfTheDayData']
}
</script>

<style scoped>
.squareFlex {
  /*justify-content: space-between;*/
  margin: auto;
}

.squareHolder {
  /*
  position: absolute;
  top: 50%;
  left: 50%;
   */
}

.square {
  /*background: aquamarine;*/
  /*border: 5px solid red;*/
  display: flex;
  flex-direction: column;

  /* mobile: */

  width: 96vw;
  height: 72.5vw;

  /* desktop: */

  /*
    width: 35vw;
    height: 35vw;
  */
  /*
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  */
}

.headerRow {
  height: 27vw;
  justify-content: normal;
}

.iElement {
  text-align: center;
}

.flexGrowElement {
  height: 27vw;
  justify-content: normal;
}

.fieldsHolder {
  height: 100%;
  /*background: floralwhite;*/
  display: flex;
  flex-direction: column;
}

.growElement {
  flex-grow: 3;
}

.bottomRow {
  height: 27vw;
  justify-content: normal;
}

.bottomFlexRow {
  display: flex;
  flex-direction: row;
}

.moneyElement {
  text-align: center;
  justify-content: flex-start;
}

.peoplesElement {
  text-align: center;
  justify-content: flex-end;
  margin-left: auto;
}
</style>