<template>
  <div class="buttonHolder">
    <button :class="buttonClass" role="button" @click="clickHandler">
      <span class="buttonLabel">{{ statusResultsData?.backButtonLabel }}</span>
    </button>
  </div>
</template>

<script>
import router from "@/main";

export default {
  name: "BackButtonResultsPage",
  props: ['statusResultsData'],

  data() {
    return {
      buttonClass: null,
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.buttonClass = "buttonDark";
    } else {
      this.buttonClass = "buttonLight";
    }
  },

  methods: {
    clickHandler() {
      router.push("/statusOfTheDay");
    }
  },
}
</script>

<style scoped>
.buttonHolder {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}

.buttonLabel {
  font-size: 5vw;
}

.buttonDark {
  width: 90vw;
  height: 10vh;
  background-color: var(--button-background-dark);
  border-radius: 100px;
  /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
  color: var(--button-label-dark);
  cursor: pointer;
  display: inline-block;
  font-family: Garamond;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color:transparent;
}

.buttonLight {
  width: 90vw;
  height: 10vh;
  background-color: var(--button-background-light);
  border-radius: 100px;
  /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
  color: var(--button-label-light);
  cursor: pointer;
  display: inline-block;
  font-family: Garamond;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color:transparent;
}

/*
.button-33:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05);
}
 */
</style>