<template>
     <div :class="[isDarkTheme ? itemCardClassDark : itemCardClassLight ]">
        <div> {{ success.added }}</div>
        <div>{{ success.content }}</div>
        
        <div class="removeImgHolder">
            <img src="../../../assets/images/trash.png" class="removeSuccessImg" @click="removeSuccess(success.successId)" />
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'SuccessItem',
    props: {
        success: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            itemCardClassLight: 'itemCardLight',
            itemCardClassDark: 'itemCardDark',
            isDarkTheme: window.Telegram.WebApp.colorScheme === 'dark',
        }
    },

    methods: {
        removeSuccess(successId) {
            this.$emit('removeSuccess', successId);
        }
    }
}
</script>

<style scoped>
.itemCardDark {
    display: flex;
    flex-direction: column;

    margin-top: 3vh;
    border-radius: 1vh;
    border: 2px solid var(--field-foreground-dark);
    padding: 5vw;
    width: 80vw;

    font-family: Garamond, serif;
    font-size: 5vw;

    box-shadow: 0 4px 21px -12px rgba(255, 255, 255, .66);
    transition: box-shadow 0.2s ease, transform 0.2s ease;

    /*background: linear-gradient(rgba(255, 187, 53, .12), rgba(249, 255, 252, .12));*/
    background: linear-gradient(rgba(0, 33, 0, .12), rgba(0, 33, 155, .12));

    color: var(--text-foreground-dark);
}


.itemCardLight {
    display: flex;
    flex-direction: column;

    margin-top: 3vh;
    border-radius: 1vh;
    border: 2px solid var(--field-foreground-light);
    padding: 5vw;
    width: 80vw;

    font-family: Garamond, serif;
    font-size: 5vw;

    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, .66);
    transition: box-shadow 0.2s ease, transform 0.2s ease;

    background: linear-gradient(rgba(255, 187, 53, .12), rgba(249, 255, 252, .12));

    color: var(--text-foreground-light);
}

.itemCardLight::before {
    background-image: linear-gradient(-70deg, #F5AF41, transparent 50%);
}

.itemCardLight:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #F5AF41, transparent 50%);
}

.itemCardDark::before {
    background-image: linear-gradient(-70deg, #F5AF41, transparent 50%);
}

.itemCardDark:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #F5AF41, transparent 50%);
}

/*
.itemCard-customColor::before {
    background-image: linear-gradient(-70deg, #F5AF41, transparent 50%);
}

.itemCard-customColor:nth-child(2n)::before {
    background-image: linear-gradient(-250deg, #F5AF41, transparent 50%);
}
*/
.removeImgHolder {
    display: flex;
    flex-direction: row;
}

.removeSuccessImg {
    /*justify-content: flex-start;*/
    margin-top: 1vh;
    width: 6vw;
    height: 6vw;
}
</style>