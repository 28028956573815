<template>
  <div class="field1ColumnFlex">
    <span class="field1Caption">{{ examplePage1Data?.ifieldLabel }}</span>
    <span :class="field1ExplanationClass">({{ examplePage1Data?.ifieldLabelHelpCaption }})</span>
  </div>
</template>

<script>
export default {
  name: "IExamplePage1",
  props: ['examplePage1Data'],

  data() {
    return {
      field1ExplanationClass: null,
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.field1ExplanationClass = "field1ExplanationDark";
    } else {
      this.field1ExplanationClass = "field1ExplanationLight";
    }
  }
}
</script>

<style scoped>
.field1ColumnFlex {
  display: flex;
  flex-direction: column;
}

.field1Caption {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: darkgoldenrod;
  filter: brightness(1.15);
}

.field1ExplanationDark {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: #BABABA;
}

.field1ExplanationLight {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: dimgray;
  filter: brightness(0.75);
}
</style>