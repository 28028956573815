<template>
  <div class="field1ColumnFlex">
    <span :class="field1CaptionClass" @click="clickHandler">{{ statusOfTheDayData?.iamFieldLabel }}</span>

    <img :src=statusOfTheDayData?.iamSmilePath
         class="field1Img" @click="clickHandler" />
  </div>
</template>

<script>
import router from "@/main";

export default {
  name: "IElementStatusOfTheDay",
  props: ['statusOfTheDayData'],

  data() {
    return {
      field1CaptionClass: null,
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.field1CaptionClass = 'field1CaptionDark';
    } else {
      this.field1CaptionClass = 'field1CaptionLight';
    }
  },

  methods: {
    clickHandler() {
      router.push("/updateStatusOfTheDay/1");
    }
  }
}
</script>

<style scoped>
.field1ColumnFlex {
  display: flex;
  flex-direction: column;
}

.field1CaptionDark {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-dark);
  filter: brightness(1.15);
}

.field1CaptionLight {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-light);
  filter: brightness(1.15);
}

.field1Img {
  width: 12vw;
  height: 12vw;
  align-self: center;
}
</style>