<template>
  <div class="field3ColumnFlex">
    <span :class="field3CaptionClass" @click="clickHandler">{{ statusOfTheDayData?.peoplesFieldLabel }}</span>

    <img :src="statusOfTheDayData?.peoplesSmilePath"
         class="field3Img" @click="clickHandler"/>
  </div>
</template>

<script>
import router from "@/main";

export default {
  name: "PeoplesElementStatusOfTheDay",
  props: ['statusOfTheDayData'],

  data() {
    return {
      field3CaptionClass: null,
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') { 
      this.field3CaptionClass = 'field3CaptionDark';
    } else {
      this.field3CaptionClass = 'field3CaptionLight';
    }
  },

  methods: {
    clickHandler(){
      router.push("/updateStatusOfTheDay/3");
    }
  }
}
</script>

<style scoped>
.field3ColumnFlex {
  display: flex;
  flex-direction: column;
}

.field3CaptionDark {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-dark);
  filter: brightness(1.15);
}


.field3CaptionLight {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-light);
  filter: brightness(1.15);
}

.field3Img {
  width: 12vw;
  height: 12vw;
  align-self: center;
}
</style>