<template>
  <div class="mainColumn">

    <div class="titleContainer">
      <span :class="titleClass">{{ statusResultsData?.title }}</span>
    </div>

    <div class="periodSelector">
      <div>
        <input type="radio" id="weekly" value="WEEKLY" v-model="periodType" @change="periodTypeChanged" />
        <label for="weekly" :class="periodLabelClass">{{ statusResultsData?.weeklyLabel }}</label>
      </div>

      <div>
        <input type="radio" id="monthly" value="MONTHLY" v-model="periodType" @change="periodTypeChanged" />
        <label for="monthly" :class="periodLabelClass">{{ statusResultsData?.monthlyLabel }}</label>
      </div>

      <div>
        <input type="radio" id="annually" value="ANNUALLY" v-model="periodType" @change="periodTypeChanged" />
        <label for="annually" :class="periodLabelClass">{{ statusResultsData?.annuallyLabel }}</label>
      </div>
    </div>

    <div class="periodBackNextSelector">
      <img src="../../../assets/images/left-arrow.png" class="leftArrow" @click="leftClick" />

      <div :class="periodViewClass">
        {{ statusResultsData?.periodView }}
      </div>

      <img src="../../../assets/images/right-arrow.png" class="rightArrow" @click="rightClick" />
    </div>
  </div>
</template>

<script>
import router from "@/main";
import axios from "axios";

export default {
  name: "PeriodBackNextSelector",
  props: ['statusResultsData'],

  data() {
    return {
      currentPeriodId: null,
      periodId: null,
      periodType: 'WEEKLY',

      titleClass: null,
      periodLabelClass: null,
      periodViewClass: null,
    };
  },

  mounted() {
    if (window.Telegram.WebApp.colorScheme === 'dark') {
      this.titleClass = 'titleDark';
      this.periodLabelClass = 'periodLabelDark';
      this.periodViewClass = 'periodViewDark';
    } else {
      this.titleClass = 'titleLight';
      this.periodLabelClass = 'periodLabelLight';
      this.periodViewClass = 'periodViewLight';
    }
  },

  methods: {
    leftClick() {
      if (this.statusResultsData.periodId > this.statusResultsData.periodStartId) {
        this.$emit('leftClicked');
      }

    },

    rightClick() {
      if (this.statusResultsData.periodId < this.statusResultsData.periodEndId) {
        this.$emit('rightClicked');
      }
    },

    periodTypeChanged() {
      this.$emit('periodTypeChanged', this.periodType);
    },
  },
}
</script>

<style scoped>
.mainColumn {
  display: flex;
  flex-direction: column;
}

.periodSelector {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.0vh;
}

.periodBackNextSelector {
  width: 96vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.0vh;
}

.titleContainer {
  align-self: center;
}

.titleDark {
  color: var(--text-foreground-dark);
  margin: auto;
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.titleLight {
  color: var(--text-foreground-light);
  /*filter: brightness(0.75);*/
  margin: auto;
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.periodViewDark {
  color: var(--text-foreground-dark);
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
  margin-top: 0.75vh;
}

.periodViewLight {
  color: var(--text-foreground-light);
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
  margin-top: 0.75vh;
}

.periodLabelDark {
  color: var(--text-foreground-dark);
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.periodLabelLight {
  color: var(--text-foreground-light);
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.leftArrow {
  display: flex;
  width: 7.5vw;
  height: 7.5vw;
  position: fixed;
  margin-left: -75vw;
  margin-top: 0.9vh;
}

.rightArrow {
  display: flex;
  width: 7.5vw;
  height: 7.5vw;
  position: fixed;
  margin-left: +75vw;
  margin-top: 0.9vh;
}
</style>