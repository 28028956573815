<template>
  <div class="squareFlex">
      <div class="square">
          <div class="headerRow">
            <IExamplePage1 class="iElement" v-bind:example-page1-data="examplePage1Data" />
          </div>

          <div class="flexGrowElement"/>

          <div class="bottomRow">
            <div class="bottomFlexRow">
              <money-example-page1 class="moneyElement" v-bind:example-page1-data="examplePage1Data" />

              <peoples-example-page1 class="peoplesElement" v-bind:example-page1-data="examplePage1Data" />
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import IExamplePage1 from "@/components/ui/ExamplePage1/IExamplePage1";
import MoneyExamplePage1 from "@/components/ui/ExamplePage1/MoneyExamplePage1";
import PeoplesExamplePage1 from "@/components/ui/ExamplePage1/PeoplesExamplePage1";

export default {
  name: "MainSquareExamplePage1",
  components: {IExamplePage1, MoneyExamplePage1, PeoplesExamplePage1 },
  props: ['examplePage1Data']
}
</script>

<style scoped>
.squareFlex {
  margin: auto;
}

.squareHolder {
  /*
  position: absolute;
  top: 50%;
  left: 50%;
   */
}

.square {
  /*background: aquamarine;*/
  /*border: 5px solid red;*/
  /*text-align: center;*/
  display: flex;
  flex-direction: column;

/* mobile: */

  width: 96vw;
  height: 80vw;

/* desktop: */

/*
  width: 35vw;
  height: 35vw;
*/

  /*
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  */
}

.fieldsHolder {
  width: 96vw;
  height: 80vw;
  /*background: cornflowerblue;*/
  display: grid;
  grid-template-columns: 2fr auto 2fr auto 2fr;
  grid-template-rows: 1fr auto 1fr;
}

.headerRow {
  height: 27vw;
  justify-content: normal;
}

.flexGrowElement {
  height: 27vw;
  justify-content: normal;
}

.bottomRow {
  height: 27vw;
  justify-content: normal;
}

.bottomFlexRow {
  display: flex;
  flex-direction: row;
}

.iElement {
  text-align: center;
}

.moneyElement {
  text-align: center;
  justify-content: flex-start;
}

.peoplesElement {
  text-align: center;
  justify-content: flex-end;
  margin-left: auto;
}

.gridGrowElement {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
  /*height: 30vh;*/
}

.growElement {
  flex-grow: 3;
}

.linesImg {
  width: 30vw;
}

.bottomRow {
  /*background: greenyellow;*/
  /*justify-content: flex-end;*/
}

.bottomFlexRow {
  display: flex;
  flex-direction: row;
}

.field2Content {
  /*background: aqua;*/
}

.field3Content {
  justify-content: flex-end;
}
</style>