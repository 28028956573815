<template>
  <div class="field3ColumnFlex">
    <span class="field3Caption">{{ examplePage2Data?.peoplesFieldLabel }}</span>
    <img src="../../../../res/icons/rest.png" class="field3Img" />
    <span :class="field3ExplanationClass">{{ examplePage2Data?.restSmileCaption }}</span>
  </div>
</template>

<script>
export default {
  name: "PeoplesExamplePage2",
  props: ['examplePage2Data'],

  data() {
    return {
      field3ExplanationClass: null,
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.field3ExplanationClass = "field3ExplanationDark";
    } else {
      this.field3ExplanationClass = "field3ExplanationLight";
    }
  }
}
</script>

<style scoped>
.field3ColumnFlex {
  display: flex;
  flex-direction: column;
}

.field3Caption {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: darkgoldenrod;
  filter: brightness(1.15);
}

.field3Img {
  width: 12vw;
  height: 12vw;
  align-self: center;
}

.field3ExplanationDark {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: #BABABA;
}

.field3ExplanationLight {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: dimgray;
  filter: brightness(0.75);
}
</style>