<template>
  <div class="mainColumn">

    <div class="topElement">
      <div class="navigationBar">
        <div class="navigationCenterElement">
          <div class="navigationCheckBoxes">
            <img :src="firstCheckBox" class="prjCheckBox" />
            <img :src="nextArrow" class="prjCheckBox" />
            <img :src="secondCheckBox" class="prjCheckBox" />
            <img :src="nextArrow" class="prjCheckBox" />
            <img :src="thirdCheckBox" class="prjCheckBox" />
          </div>
        </div>
      </div>

      <div class="titleFlex">
        <div :class="titleCenterElementClass">
          {{ examplePage1Data?.title }}
        </div>
      </div>

    </div>

    <MainSquareExamplePage1 v-bind:example-page1-data="examplePage1Data" />

    <NextButtonExamplePage1 v-bind:example-page1-data="examplePage1Data" />

  </div>

</template>

<script>
import MainSquareExamplePage1 from "@/components/ui/ExamplePage1/MainSquareExamplePage1";
import NextButtonExamplePage1 from "@/components/ui/ExamplePage1/NextButtonExamplePage1";
import axios from "axios";
import router from "@/main";

export default {
  components: { NextButtonExamplePage1, MainSquareExamplePage1 },

  data() {
    return {
      examplePage1Data: null,
      titleCenterElementClass: null,
      firstCheckBox: null,
      nextArrow: null,
      secondCheckBox: null,
      thirdCheckBox: null,
    }
  },

  async mounted() {
    let initDataBase64 = btoa(window.Telegram.WebApp.initData);

    if (window.Telegram.WebApp.colorScheme === 'dark') {
      this.titleCenterElementClass = "titleCenterElementDark";
      this.firstCheckBox = "checked-dark.png";
      this.nextArrow = "next-dark.png";
      this.secondCheckBox = "unchecked-dark.png";
      this.thirdCheckBox = "unchecked-dark.png";
    } else {
      this.titleCenterElementClass = "titleCenterElementLight";
      this.firstCheckBox = "checked-light.png";
      this.nextArrow = "next-light.png";
      this.secondCheckBox = "unchecked-light.png";
      this.thirdCheckBox = "unchecked-light.png";
    }

    const today = new Date();
    let timezoneOffset = today.getTimezoneOffset();

    await axios
      .put(`${this.hostname}/userBase/saveUserTimezone?initData=${initDataBase64}&timezone=${timezoneOffset}`)
      .catch(error => {
        alert(error);
        if (error.response && error.response.status === 401) {
          router.push("/unathorized");
        } else {
          router.push('/serverErrorPage')
        }
      });

    await axios
      .get(`${this.hostname}/serverErrorCaption/getData?initData=${initDataBase64}`)
      .then(response => {
        if (response.status === 200) {
          this.app.config.globalProperties.serverErrorCaption = response.data;
        }
      })
      .catch(error => {
        router.push('/serverErrorPage')
      });

    await axios
      .get(`${this.hostname}/example?pageIndex=1&initData=${initDataBase64}`)
      .then(response => {
        if (response.status === 200) {
          this.examplePage1Data = response.data;
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          router.push("/unathorized");
        } else {
          router.push('/serverErrorPage')
        }
      });
  }
}

</script>

<style scoped>
.mainColumn {
  height: calc(var(--tg-viewport-stable-height) - 4vh);
  display: flex;
  flex-direction: column;
  /*background: cornflowerblue;*/
}

.topElement {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.navigationBar {
  height: 5vh;
  justify-content: flex-start;
  align-self: center;
}

.navigationCenterElement {
  color: dimgray;
}

.navigationCheckBoxes {
  display: flex;
  flex-direction: row;
}

.prjCheckBox {
  width: 5vw;
  height: 5vw;
  justify-content: space-between;
}

.titleFlex {
  justify-content: flex-end;
  /*height: 6vh;*/
  align-self: center;
}

.titleCenterElementDark {
  color: #BABABA;
  margin: auto;
  font-family: Garamond, serif;
  font-size: 4.5vh;
  text-align: center;
}

.titleCenterElementLight {
  color: dimgray;
  filter: brightness(0.75);
  margin: auto;
  font-family: Garamond, serif;
  font-size: 4.5vh;
  text-align: center;
}
</style>