<template>
  <div class="mainColumn">
    <div class="titleCenterElement">
      <span>Unathorized user!</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnathorizedPage"
}
</script>

<style scoped>
.mainColumn {
  height: calc(var(--tg-viewport-stable-height) - 4vh);
  display: flex;
  flex-direction: column;
}

.titleCenterElement {
  margin: auto;
  font-family: Garamond, serif;
  /*font-family: Windsor, serif;*/
  font-size: 4.5vh;
  color: dimgray;
  text-align: center;
  filter: brightness(0.75);
}
</style>