<template>
  <div class="squareFlex">
      <div class="square">

        <div class="headerRow">
          <i-example-page2 class="iElement" v-bind:example-page2-data="examplePage2Data"/>
        </div>

        <div class="flexGrowElement"/>

        <div class="bottomRow">
          <div class="bottomFlexRow">
            <money-example-page2 class="moneyElement" v-bind:example-page2-data="examplePage2Data"/>

            <peoples-example-page2 class="peoplesElement" v-bind:example-page2-data="examplePage2Data"/>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import IExamplePage2 from "@/components/ui/ExamplePage2/IExamplePage2";
import MoneyExamplePage2 from "@/components/ui/ExamplePage2/MoneyExamplePage2";
import PeoplesExamplePage2 from "@/components/ui/ExamplePage2/PeoplesExamplePage2";

export default {
  name: "MainSquareExamplePage2",
  components: {IExamplePage2, MoneyExamplePage2, PeoplesExamplePage2 },
  props: ['examplePage2Data']
}
</script>

<style scoped>
.squareFlex {
  /*justify-content: space-between;*/
  margin: auto;
}

.squareHolder {
  /*
  position: absolute;
  top: 50%;
  left: 50%;
   */
}

.square {
  /*background: aquamarine;*/
  /*border: 5px solid red;*/
  display: flex;
  flex-direction: column;

  /* mobile: */

  width: 96vw;
  height: 72.5vw;

  /* desktop: */

/*
  width: 35vw;
  height: 35vw;
*/
  /*
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  */
}

.headerRow {
  height: 27vw;
  justify-content: normal;
}

.iElement {
  text-align: center;
}

.flexGrowElement {
  height: 27vw;
  justify-content: normal;
}

.fieldsHolder {
  height: 100%;
  /*background: floralwhite;*/
  display: flex;
  flex-direction: column;
}

.growElement {
  flex-grow: 3;
}

.bottomRow {
  height: 27vw;
  justify-content: normal;
}

.bottomFlexRow {
  display: flex;
  flex-direction: row;
}

.moneyElement {
  text-align: center;
  justify-content: flex-start;
}

.peoplesElement {
  text-align: center;
  justify-content: flex-end;
  margin-left: auto;
}

.field2Content {
  /*background: aqua;*/
}

.field3Content {
  justify-content: flex-end;
}
</style>