<template>
  <div class="squareFlex">
      <div class="square">
        <div class="fieldsHolder">
            <VisualResultsPage v-bind:results-mode="false"/>
        </div>
      </div>
  </div>
</template>

<script>
import VisualResultsPage from '../ResultsPageUI/VisualResultsPage.vue';

export default {
  name: "MainSquareExamplePage3",
  components: {
    VisualResultsPage
  },
  props: ['examplePage3Data']
}
</script>

<style scoped>
.squareFlex {
  /*justify-content: space-between;*/
  margin: auto;
}

.squareHolder {
  /*
  position: absolute;
  top: 50%;
  left: 50%;
   */
}

.square {
  /*background: aquamarine;*/
  /*border: 5px solid red;*/
  text-align: center;

  /* mobile: */

    width: 96vw;
    height: 60vw;

  /* desktop: */

  /*
  width: 35vw;
  height: 35vw;
*/

  /*
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  */
}

.fieldsHolder {
  height: 60vw;
  /*background: floralwhite;*/
  display: flex;
  flex-direction: column;
  margin-top: -15vh;
}
</style>