<template>
  <div class="field2ColumnFlex">
    <span :class="field2CaptionClass" @click="clickHandler">{{ statusOfTheDayData?.moneyFieldLabel }}</span>

    <img :src="statusOfTheDayData?.moneySmilePath"
         class="field2Img" @click="clickHandler"/>
  </div>
</template>

<script>
import router from "@/main";

export default {
  name: "MoneyElementStatusOfTheDay",
  props: ['statusOfTheDayData'],

  data() {
    return {
      field2CaptionClass: null,
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.field2CaptionClass = 'field2CaptionDark';
    } else {
      this.field2CaptionClass = 'field2CaptionLight';
    }
  },

  methods: {
    clickHandler(){
      router.push("/updateStatusOfTheDay/2");
    }
  }
}
</script>

<style scoped>
.field2ColumnFlex {
  display: flex;
  flex-direction: column;
}

.field2CaptionDark {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-dark);
  filter: brightness(1.15);
}

.field2CaptionLight {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-light);
  filter: brightness(1.15);
}

.field2Img {
  width: 12.75vw;
  height: 12.75vw;
  align-self: center;
}
</style>